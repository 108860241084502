<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "app",
  created() {
  },
  mounted() {
  }
};
</script>

<style>
@import "assets/iconfont/iconfont.css";
</style>
