import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/axios'
import './assets/css/common.scss'
import ElementUI from 'element-ui';
import './plugins/element'
import "@/assets/font/font.css"
import { getProxy } from './plugins/axios'

Vue.config.productionTip = false
// eslint-disable-next-line no-undef
// document.title = titleName;


Vue.use(ElementUI);

async function init(){
    await getProxy()
    new Vue({
        router,
        render: h => h(App)
    }).$mount('#app');
    
}
init()