
class CacheService {
  constructor() { }

  origin(key, type) {
    let item = type ? window.localStorage.setItem(key) : window.sessionStorage.getItem(key)
    return item !== null && JSON.parse(item) || void 0
  }

  get(key) {
    let item
    return (item = window.sessionStorage.getItem(key)) !== null ?
      JSON.parse(item) : (item = window.localStorage.getItem(key)) === null ?
        void 0 : JSON.parse(item).data
  }

  set(key, value, type) {
    if (type) {
      window.sessionStorage.setItem(key, JSON.stringify(value),)
    } else {
      window.localStorage.setItem(key, JSON.stringify({
        data: value,
        timestamp: Date.now()
      }))
    }
  }

  remove(key) {
    window.sessionStorage.removeItem(key)
    window.localStorage.removeItem(key)
  }

  clear() {
    window.sessionStorage.clear()
    window.localStorage.clear()
  }

}

export default CacheService