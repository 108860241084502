import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/index"),
  },
];

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  if (window.location.hash.slice(1) === location) {
    return;
  }
  return originalReplace.call(this, location).catch((err) => err);
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  if (window.location.hash.slice(1) === location) {
    return;
  }
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/"  || to.path == '/login') {
    next();
    return;
  }
  next();
});

export default router;
