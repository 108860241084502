/**
 * Template
 * created by qiangxu on 2020/9/14
 */
import { CacheService } from "./common"

export class TokenService {

  static get() {
    if (!this.$token) {
      this.$token = CacheService.get("token");
    }
    return this.$token;
  }

  static set(token) {
    this.$token = token;
    CacheService.set("token", token, true);
  }

  static remove() {
    this.$token = null;
    CacheService.remove("token",);
  }

  static access() {
    const token = this.get();
    return token && token.accessToken;
  }

  static refresh() {
    const token = this.get();
    return token && token.refreshToken;
  }

  static timestamp() {
    const data = CacheService.origin("token", true);
    return data && data.timestamp;
  }
}
