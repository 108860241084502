import Vue from 'vue'
import axios from 'axios'
import {TokenService} from '../service/token'
import {Message, Loading} from "element-ui"
import {CacheService} from "@/service/common";

let config = {
    headers: {
        'Content-Type': 'application/json'
    },
    
}


let loading = null


let api = window.location.host;
if (process.env.NODE_ENV !== "development") {
    let apiList = window.location.host.split('.')
    apiList[0] = 'api'
    api = apiList.join('.') + '/api'
    // config.baseURl = "//api.maidiyun.cn/api"
} 
// api="localhost:8080/api"
let request = axios.create(config);
export async function getProxy(){

    const res = await axios.get(`//${api}/OpencloudManager/SetConfiguration/SetConfigurationList`)
    res.data.data.forEach(d => {
        config[d.keyName] = d.keyValue
        Vue.prototype['$' + d.keyName] = d.keyValue
    })


    request.interceptors.request.use(data => {
        data.baseURL = config.baseURL
        if (process.env.NODE_ENV == "development") {
            data.baseURL = "/api"
        }
        loading = Loading.service({
            text: 'Loading',
            background: 'transparent'
        })
        // Do something before request is sent
        return data;
    }, err => Promise.reject(err))
    
    /*是否有请求正在刷新token*/
    let isRefreshing = false
    let refreshSubscribers = []
    
    request.interceptors.response.use(response => {
        loading && loading.close()
        if (response.data.code === 200) {
            return response.data.data
        } else {
            Message.error(response.data.errormsg)
            return Promise.reject(response.data.errormsg)
        }
    }, error => {
        const response = error.response
        loading && loading.close()
        if (response.status === 401) {
            const user = TokenService.get();
            if (!isRefreshing && user) {
                isRefreshing = true
                request.get(`Yunos/User/RefreshToken?refreshToken=${encodeURIComponent(user.refreshToken)}`).then(res => {
                    isRefreshing = false
                    if (res.accessToken) {
                        TokenService.set({...user, ...res});
                        request.defaults.headers.common.Authorization = `Bearer ${res.accessToken}`;
                        refreshSubscribers.forEach(cb => cb(res.accessToken))
                        refreshSubscribers = []
                    }
                }, () => {
                    isRefreshing = false
                    CacheService.clear();
                    request.get(`/Yunos/User/ApplySecret?applyIdDto=10000001`).then((res) => {
                        top.location = `${config.ssoUrl}/Acount/Authlogin?clientId=10000001&clientSecret=${res.applySecret}&redirect=${config.localUrl}&timespan=${res.timeStamp}`
                    });
                })
            }
            let retry = new Promise((resolve, reject) => {
                refreshSubscribers.push((token) => {
                    response.config.headers.Authorization = 'Bearer ' + token
                    request(response.config).then((res) => {
                        resolve(res)
                    });
                })
            })
            return retry
        } else if (response.status === 419) {
            Message.error('该账号已在其他终端登录，请重新登录！')
        } else if (response.status === 403) {
            Message.error('暂无权限，请联系管理员授权')
            return Promise.reject(error);
        } else {
            Message.error('请求失败，请重试')
            return Promise.reject(error);
        }
    })
    
    Plugin.install = function (Vue, options) {
        Vue.axios = request;
        window.axios = request;
        Object.defineProperties(Vue.prototype, {
            axios: {
                get() {
                    return request;
                }
            },
            $axios: {
                get() {
                    return request;
                }
            },
        });
    };
    // Vue.prototype.axios = request
    Vue.use(Plugin);
}



export default request;
